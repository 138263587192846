.root {
  margin: 130px auto 140px;
  display: flex;
  padding: 0 16px;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-top: 60px;
  margin-bottom: 13px;
}
.btn {
  margin-top: 36px;
}
